import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import SignUp from './pages/SignUp';
import UsernameSetup from './pages/UsernameSetup';
import Profile from './pages/Profile';
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Creators from "./pages/Creators";
import Home from "./pages/Home";
import {HelmetProvider} from "react-helmet-async";

const App: React.FC = () => {
    return (

        <HelmetProvider>
            <Routes>
                <Route path="/creator/sign-up" element={<SignUp/>}/>
                <Route
                    path="/creator/setup-username" element={<UsernameSetup/>}
                    // element={currentUser ? <UsernameSetup /> : <SignUp />}
                />

                <Route path="/privacy" element={<Privacy/>}/>
                <Route path="/terms" element={<Terms/>}/>
                <Route path="/creators" element={<Creators/>}/>
                <Route path="/" element={<Home/>}/>
                <Route path="/:username" element={<Profile/>}/>

                {/* Wildcard route: redirects any undefined route to Home Page */}
                <Route path="*" element={<Navigate to="/"/>}/>
            </Routes>
        </HelmetProvider>
    );
};

export default App;
